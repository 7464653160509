
/* @font-face {
  font-family: "GoldmanBold";
  src: local("GoldmanBold"), url("./src/assets/Fonts/Vazir-Bold.ttf") format("truetype");
  font-weight: bold;
  } */

@font-face {
  font-family: "vazir";
  src: url("/src/assets/Fonts/Vazir-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "vazir";
  src: url("/src/assets/Fonts/Vazir-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "vazir";
  src: url("/src/assets/Fonts/Vazir-Light.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "vazir";
  src: url("/src/assets/Fonts/Vazir-Thin.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "vazir";
  src: url("/src/assets/Fonts/Vazir.ttf");
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "vazir", BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "vazir", BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
